<template lang="pug">
.fixed-bottom.full-width.row.justify-center.items-center.bg-secondary.text-center.q-py-sm.plashka(v-if="isShow")
    .font-18.text-positive.text-weight-medium Внимание! Указанная цена действительна только при заказе через сайт
    q-btn.q-ml-md(
        flat
        round
        fab-mini
        v-close-popup
        @click="closePlash")
        q-icon(
            name="etabl:cross"
            size="xs"
            color="grey-4")
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'Plashka',
    setup() {
        const isShow = ref(true);

        const closePlash = () => {
            isShow.value = false;
        };

        return {
            isShow,
            closePlash,
        };
    },
};
</script>

<style lang="scss" scoped>
.plashka {
    z-index: 2001;
}
</style>
